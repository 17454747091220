import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import { mapDefaultTasq } from '@/utils/tasqs';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import predictionsApolloClient from '@/lib/appsync/workflow';
import getTasqsLists from '@/graphql/predictions/mutations/getTasqsLists.graphql';
import searchTasqs from '@/graphql/predictions/mutations/searchTasqsList.graphql';
import getMyWaitList from '@/graphql/workflow/queries/getMyWaitList.graphql';
import getTasq from '@/graphql/workflow/queries/getTasq.graphql';
import getTasqEventHistory from '@/graphql/workflow/queries/getTasqEventHistory.graphql';
import getAllTasqResponses from '@/graphql/workflow/queries/getAllTasqResponses.graphql';
import { Debounce } from 'vue-debounce-decorator';
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import GetTasqsListsResponse from '@/graphql/predictions/interfaces/GetTasqsListsResponse';
import SearchTasqsResponse from '@/graphql/predictions/interfaces/SearchTasqsListsResponse';
import TasqJobResponse from '@/graphql/predictions/interfaces/TasqJobResponse';
import accountModule from '@/store/modules/accountModule';
import workflowApolloClient from '@/lib/appsync/workflow';
import workflowModule from '@/store/modules/workflowModule';
import tasqsListModule from '@/store/modules/tasqsListModule';
import isOnline from 'is-online';
import localForage from '@/lib/localForage';
import workspaceExample from '@/router/routes/workspaceExample';
import workspaceModule from './workspaceModule';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'tasqsListModule',
  store,
})
class TasqsListModule extends VuexModule {
  tasqEventHistoryList: any[] = [];

  completedTasqList: TasqJob[] = [];

  waitingTasqList: TasqJob[] = [];

  tasqList: TasqJob[] = [];

  allTasqs: TasqJob[] = [];

  padNestedTasqs: TasqJob[] = [];

  additionalPadNestedTasqs: TasqJob[] = [];

  activeTasq: TasqJob|null|undefined = null;

  activeOfflineTasq: TasqJob|null|undefined = null;

  activeProducingTasq: TasqJob|null|undefined = null;

  activePage = '';

  kanbanSelectedTasq: any = '';

  showTasqDetailsMobile = false;

  activeFilterRoute: any = [];

  isLoadingTasqResponses = false

  activeSubstringFilter = '';

  activeListType = '';

  tasqListLevel = accountModule.user != null && accountModule.user.role != null && (accountModule.user.role == 'Operators' || accountModule.user.role == 'FieldOperator') ? 'Pad' : 'Well'

  activeFilterList: any = null;

  signalChartTime = 14;

  isLoadingTasqs = false

  isLoadingSearchTasqs = false

  activeTasqScroll: any = null;

  tasqStartLimit: any = 0;

  tasqEndLimit: any = 30;

  progressPercentage: any = 0;

  saveOffline = false;

  defermentDays: any = 0;

  signalChartTimeObj: any =  null;

  cycleTimeLessThanFilter: any = null;

  unitsGreaterThanFilter: any = null;

  unitsLessThanFilter: any = null;

  cycleTimeGreaterThanFilter: any = null;


  cycleTimeDays: any = 0;

  jobTypeFilter: any = [];

  tasqLimitPerPage: any = 30;

  tasqTotalPages: any = 1;

  usernamesList: any = [];

  paginationLoading: any = true;

  batchResponseEnabled: any = false;

  fromNowBack = 0;

  activeFilterTeam = [];

  isBatchResponding: boolean = false

  batchResponseDisabledMsg = 'Please select at least 1 tasq';

  currentTasqListPageNo: any = 1;

  todaysTasqLength: any = 0;

  infiniteScrollHeight: any = 0;

  activePredictionTypeFilter: any = []

  allPredictionTypes: any = []

  tasqResponseResults: any = []

  checkedTasqs: any = []

  isEditing: boolean = false

  isBatchReassign: boolean = false;


  @Mutation
  setIsEditing(val): void {
    this.isEditing = val;
  }

  @Mutation
  setIsBatchResponding(val): void {
    this.isBatchResponding = val;
  }

  @Mutation
  setIsBatchReassign(val): void {
    this.isBatchReassign = val;
  }

  // @Mutation
  // setActiveOfflineTasq(tasq): void {
  //   this.activeOfflineTasq = tasq;
  // }

  @Mutation
  setTasqEventHistoryList(event_list): void {
    const event_list_results: any[] = [];
    for (let x = 0; x < event_list.length; x++) {
      if(JSON.parse(event_list[x]).type !== 'JOB_LABELED') {
        event_list_results.push(JSON.parse(event_list[x]));
      }
    }
    event_list_results.sort((a, b) => b.time.localeCompare(a.time));
    this.tasqEventHistoryList = event_list_results;
  }

  @Mutation
  setBatchResponseEnabled(): void {
	  var checkedFullTasqResponses: any[] = [];
	  for (var x = 0; x < this.checkedTasqs.length; x++) {
		  var checkedTasq = tasqsListModule.tasqById(this.checkedTasqs[x]);
		  var didAddResponseData = false;
		  for (var y = 0; y < this.tasqResponseResults.length; y++) {
        if (this.checkedTasqs[x] == this.tasqResponseResults[y]["PredictionID"]) {
				checkedTasq!["ResponseData"] = this.tasqResponseResults[y]["ResponseData"];
				didAddResponseData = true;
				break;
        }
		  }
		  if (!didAddResponseData && checkedTasq) {
			checkedTasq["ResponseData"] = {"UserResponse":{}};
		  }
		  
		  checkedFullTasqResponses.push(checkedTasq);
	  }

	  var predictionType = null;
	  var responseData = null;
	  for (var x = 0; x < checkedFullTasqResponses.length; x++) {
		  if (checkedFullTasqResponses[x]["predictionType"] == "Producing") {
        this.batchResponseEnabled = false;
        this.batchResponseDisabledMsg = 'Cannot respond to type Producing.';  
		  }
		  if (predictionType == null) {
        predictionType = checkedFullTasqResponses[x]["predictionType"];
        responseData = checkedFullTasqResponses[x]["ResponseData"]["UserResponse"];
		  } else {
        if (checkedFullTasqResponses[x]["predictionType"] != predictionType) {
          this.batchResponseEnabled = false;
          this.batchResponseDisabledMsg = 'Tasq types must match to perform batch responses.';
          return;
        }
        if (JSON.stringify(checkedFullTasqResponses[x]["ResponseData"]["UserResponse"]) != JSON.stringify(responseData)) {
          this.batchResponseEnabled = false;
          this.batchResponseDisabledMsg = 'Existing responses for the selected tasqs do not match.';
          return;
        }
		  }
	  }
	  this.batchResponseEnabled = true;
	  this.batchResponseDisabledMsg = '';
  }


  @Mutation
  checkTasq(tasqId): void {

    if (this.checkedTasqs.includes(tasqId)) {
      const index = this.checkedTasqs.indexOf(tasqId);
      if (index > -1) {
        this.checkedTasqs.splice(index, 1);
      }
    } else {
      this.checkedTasqs.push(tasqId);
    }
  }

  @Mutation
  resetChekedTasqs(): void {
	  this.checkedTasqs = [];
  }

  @Mutation
  setTasqResponseResults(data): void {
    this.tasqResponseResults = data;
  }


  @Mutation
  setSignalChartTime(data): void {
    this.signalChartTime = data;
  }

  @Mutation
  setFromNowBack(data): void {
    this.fromNowBack = data;
  }

  // @Mutation
  // setSignalChartTimeObj(data): void {
  //   this.signalChartTimeObj = data;
  // }

  @Mutation
  setPadNestedTasqs(data) {
	  this.padNestedTasqs = data;
  }

  @Mutation
  setIsLoadingTasqResponses(data) {
	  this.isLoadingTasqResponses = data;
  }
  

  @Mutation
  setKanbanSelectedTasq(tasqId): void {
    this.kanbanSelectedTasq = tasqId;
  }

  @Mutation
  setAdditionalPadNestedTasqs(data) {
	  this.additionalPadNestedTasqs = data;
  }

  @Mutation
  setTasqList({ data, listType = 'tasqList' }: {
    data: TasqJob[],
    listType?: string,
  }): void {
    this[listType] = data;
    this.allTasqs = this.allTasqs.concat(data);
  }

  @Mutation
  setPaginationLoading(value): void {
    this.paginationLoading = value;
  }

  @Mutation
  setShowTasqDetailsMobile(value): void {
    this.showTasqDetailsMobile = value;
  }

  @Mutation
  setsaveOffline(value): void {
    this.saveOffline = value;
  }

  @Mutation
  @Debounce(200)
  updateTasqList({ data, listType = 'tasqList' }: {
    data: TasqJob[],
    listType?: string,
  }): void {
    if (listType === 'tasqList') {
      data.forEach((tasq) => {
        if (tasq.id) {
          const foundIndex = this[listType].findIndex(((obj, k) => obj.id === tasq.id));
          if (foundIndex > -1) {
            this[listType][foundIndex] = tasq;
          } else {
            this[listType].push(tasq);
          }
        }
      });
    }
    this.allTasqs = this.allTasqs.concat(data);
  }

  @Mutation
  setUsernamesList(username) {
    if (!this.usernamesList.includes(username)) {
      this.usernamesList.push(username);
    } else {
      const index = this.usernamesList.indexOf(username);
      if (index > -1) {
        this.usernamesList.splice(index, 1);
      }
    }
  }

  
  @Mutation
  setUsernamesBulkList(usernames) {
    this.usernamesList = usernames
  }

  @Mutation
  setTasqListLevel(level): void {
    this.tasqListLevel = level;
  }


  @Mutation
  setProgresPercentage(data): void {
  
    this.progressPercentage +=  data;
    // console.log( this.progressPercentage)
    if(this.progressPercentage >= 89) {
      setTimeout(() => {
        this.progressPercentage = 90;
      }, 10000);
      setTimeout(() => {
        this.progressPercentage = 0;
      }, 30000);
    }
  } 
  
  @Mutation
  addProgresPercentage(data): void {
    this.progressPercentage =  data;
  }

  @Mutation
  setWaitingTasqs(data): void {
    this.waitingTasqList = data;
  }

  @Mutation
  setActiveTasq(id: string): void {
    let activeTasqDiv: any = null;
    // this.activeTasqScroll = null;
    this.activeTasq = this.allTasqs.find((i) => i.id === id);
    if (!this.activeTasq) {
      this.activeTasq = this.waitingTasqList.find((i) => i.id === id);
    }
    if (!this.activeTasq) {
		
      this.activeTasq = [...workspaceModule.totalItems, ...this.tasqList, ...this.completedTasqList, ...this.waitingTasqList, ...this.padNestedTasqs, ...this.additionalPadNestedTasqs].find((t) => id && t.id.toLowerCase() === id.toLowerCase());
    }

    if (!this.activeTasq) {
      return;
    }
    activeTasqDiv = document.getElementById(id)!;
    if (activeTasqDiv && activeTasqDiv.previousSibling) {
      this.activeTasqScroll = activeTasqDiv.previousSibling.id;
    } else if (activeTasqDiv && activeTasqDiv.nextSibling) {
      this.activeTasqScroll = activeTasqDiv.nextSibling.id;
    }
    if (this.activeTasqScroll) {
      localStorage.setItem('ActiveTasqPageNo', this.currentTasqListPageNo);
      localStorage.setItem('ActiveTasqScroll', this.activeTasqScroll);
    }
  }

  @Mutation
  setActiveTasqExplicit(tasq): void {
    this.activeTasq = tasq;
  }

  @Mutation
  setActiveFilterList(list: any): void {
    this.activeFilterList = list;
  }

  @Mutation
  setSubstringFilter(searchQuery: any): void {
    this.activeSubstringFilter = searchQuery;
  }

  @Mutation
  setPredictionTypeFilter(predictionTypes: any): void {
    this.activePredictionTypeFilter = predictionTypes;
  }

  @Mutation
  setDefermentDaysFilter(defermentDays: any): void {
    this.defermentDays = defermentDays;
  }

  @Mutation
  setCycleTimeDaysFilter(cycleTimeDays: any): void {
    this.cycleTimeDays = cycleTimeDays;
  }

  @Mutation
  setJobTypeFilter(jobTypeFilter: any): void {
    console.log(jobTypeFilter)
    this.jobTypeFilter = jobTypeFilter;
  }

  @Mutation
  setAllPredictionTypes(predictionTypes: any): void {
    // eslint-disable-next-line no-param-reassign
    predictionTypes = predictionTypes.filter(e => e !== 'state change' && e !== 'Label' && e !== 'Well down')
      // console.log(pedictionTypes)
    this.allPredictionTypes = predictionTypes;
  }
  

  @Mutation
  setInfiniteListScroll(scrollHeight: any): void {
    this.infiniteScrollHeight = scrollHeight;
  }

  @Mutation
  setTasqStartLimit(limit: any = 0): void {
    if (limit === 0) {
      this.tasqList = [];
      this.allTasqs = [];
    }
    this.tasqStartLimit = limit;
  }

  @Mutation
  resetPagination(): void {
    this.tasqList = [];
    this.allTasqs = [];
    this.tasqStartLimit = 0;
    this.tasqEndLimit = 30;
    this.tasqLimitPerPage = 30;
    this.tasqTotalPages = 1;
    this.currentTasqListPageNo = 1;
    this.todaysTasqLength = 0;
  }

  @Mutation
  setTotalTasqPagesLength(todaysTasqLength): void {
    this.tasqTotalPages = Math.ceil((todaysTasqLength) / this.tasqLimitPerPage);
    this.todaysTasqLength = todaysTasqLength;
  }

  @Mutation
  @Debounce(5000)
  updatePaginationDetails(): void {
    this.currentTasqListPageNo += 1;
    this.tasqStartLimit = this.tasqEndLimit + 1;
    this.tasqEndLimit = this.tasqStartLimit + this.tasqLimitPerPage;
  }


  @Mutation
  setActiveTeamFilter(teams): void {
    this.activeFilterTeam = teams;
  }


  @Mutation
  setCycleTimeLessThanFilter(value): void {
    this.cycleTimeLessThanFilter = value;
  }

  @Mutation
  setCycleTimeGreaterThanFilter(value): void {
    this.cycleTimeGreaterThanFilter = value;
  }

  @Mutation
  setActiveRouteFilter(routes): void {
    this.activeFilterRoute = routes;
  }


  @Mutation
  setUnitsLessThanFilter(value): void {
    this.unitsLessThanFilter = value;
  }

  @Mutation
  setUnitsGreaterThanFilter(value): void {
    this.unitsGreaterThanFilter = value;
  }



  @Mutation
  resetSetActiveTasq(): void {
    this.activeTasq = null;
  }

  @Mutation
  updateTasq({ id, data }: { id: string, data: any }): void {
    const foundTasq = this.allTasqs.find((i) => i.id === id);
    Object.assign(foundTasq, data);
  }

  @Mutation
  setActiveList(listType: string): void {
    this.activeListType = listType;
  }

  @Mutation
  setActivePage(page: string): void {
    this.activePage = page;
  }

  @Mutation
  setIsLoadingTasqs(isLoadingTasqs: boolean): void {
    this.isLoadingTasqs = isLoadingTasqs;
  }

  @Mutation
  setIsLoadingSearchTasqs(isLoadingSearchTasqs: boolean): void {
    this.isLoadingSearchTasqs = isLoadingSearchTasqs;
  }

  @Mutation
  resetTasqListLevel() {
	this.setTasqListLevel(accountModule.user != null && accountModule.user.role != null && (accountModule.user.role == 'Operators' || accountModule.user.role == 'FieldOperator') ? 'Pad' : 'Well')

  }

  @Action
  async getTasqs(list: {
    text?: string,
    val: string,
  }): Promise<void> {
    this.setIsLoadingTasqs(true);
    const queryPrams = list;
    if (this.activePage === 'Tasq') {
      this.setTasqStartLimit(0);
      this.getTasqsByPagination(list);
      return;
      // const savedActiveList = localStorage.getItem('tasqActiveList');
      // if (savedActiveList && JSON.parse(savedActiveList)) {
      //   queryPrams = JSON.parse(savedActiveList);
      // } else if (this.activeFilterList && this.activeFilterList.val) {
      //   queryPrams = this.activeFilterList;
      // }
    }

    let level = this.tasqListLevel;
    if (this.activePage !== 'Tasq') {
      level = 'Well';
    }

    this.setActiveFilterList(queryPrams);
    let variables = {
      Timezone: 'America/Denver',
      Operator: getConfigEnv('OPERATOR_LOWERCASED'),
	  Level: level,
      // @ts-ignore
      skipTotalTasqs: true,
      skipTodaysTasqs: false,
     
      
	  };


    // console.log('hi')
    // console.log(this.jobTypeFilter)

   
    if (this.activePage === 'Kanban') {
    //   console.log('hi')
     variables = {
       ...variables,
       ...((this.activeFilterTeam.length) && {Teams : this.activeFilterTeam} ),
       ...((this.activeFilterRoute.length) && {Routes: this.activeFilterRoute} ),
       ...(this.usernamesList.length && { Usernames: this.usernamesList }),
       ...(this.jobTypeFilter.length && { FilterByPredictionType: this.jobTypeFilter }),
       ...((!isNaN(Number(this.cycleTimeGreaterThanFilter)) && Number(this.cycleTimeGreaterThanFilter)) && {CycleTimeGreaterThanFilter: Number(this.cycleTimeGreaterThanFilter)}),
      ...((!isNaN(Number(this.cycleTimeLessThanFilter)) && Number(this.cycleTimeLessThanFilter) !== 0) && {CycleTimeLessThanFilter: Number(this.cycleTimeLessThanFilter)}),
      ...((!isNaN(Number(this.unitsGreaterThanFilter)) && Number(this.unitsGreaterThanFilter) !== 0) && {UnitsGreaterThanFilter: Number(this.unitsGreaterThanFilter)}),
      ...((!isNaN(Number(this.unitsLessThanFilter)) && Number(this.unitsLessThanFilter) !== 0) && {UnitsLessThanFilter: Number(this.unitsLessThanFilter)})
     }
    }



  

    // console.log(variables)
    const {
      data: {
        get_tasq_list: tasqsLists,
      },
    }: GetTasqsListsResponse = await predictionsApolloClient.query({
      query: getTasqsLists,
      variables,
    });

    this.setIsLoadingTasqs(false);
    const todaysTasqResults: any[] = [];
    const completedTasqResults: any[] = [];
    const waitingTasqResults: any[] = [];

    if (tasqsLists.PredictionTypes) {
      this.setAllPredictionTypes(tasqsLists.PredictionTypes);
    }

    for (var x = 0; x < tasqsLists.TodaysTasqResults.length; x++) {
      todaysTasqResults.push(JSON.parse(tasqsLists.TodaysTasqResults[x]));
    }
    for (var x = 0; x < tasqsLists.CompletedTasqs.length; x++) {
      completedTasqResults.push(JSON.parse(tasqsLists.CompletedTasqs[x]));
    }

    const tasqsList = jsonParse(JSON.stringify(todaysTasqResults)).map(
      (t: TasqJobResponse) => mapDefaultTasq(t),
    );
    const completed = jsonParse(JSON.stringify(completedTasqResults)).map(
      (t: TasqJobResponse) => mapDefaultTasq(t),
    );



    for (var x = 0; x < tasqsLists.WaitingOnTasqs.length; x++) {
      waitingTasqResults.push(JSON.parse(tasqsLists.WaitingOnTasqs[x]));
    }

    const waitingOn = jsonParse(JSON.stringify(waitingTasqResults)).map(
      (t: TasqJobResponse) => mapDefaultTasq(t),
    );


    this.setWaitingTasqs(waitingOn)

    this.setTasqList({
      data: tasqsList,
    });
    this.setTasqList({
      data: completed,
      listType: 'completedTasqList',
    });
    this.setActiveList(list.val);
  }

  @Debounce(10000)
  @Action
  async getTasqsByPagination(list: { text?: string, val: string}): Promise<void> {
      console.log(this.usernamesList)
	  if (this.paginationLoading) {
      this.setPaginationLoading(false);
      if (this.currentTasqListPageNo <= this.tasqTotalPages || this.tasqStartLimit === 0) {
        let queryPrams = list;
        if (this.activePage === 'Tasq') {
          let tasqUsernamesFilter: any = localStorage.getItem('TasqUsernamesFilter')
          console.log(JSON.parse(tasqUsernamesFilter))
          if (tasqUsernamesFilter && JSON.parse(tasqUsernamesFilter).length && !this.usernamesList.length) {
            tasqUsernamesFilter =  JSON.parse(tasqUsernamesFilter)
            this.setUsernamesBulkList(tasqUsernamesFilter)
          }else {
          //  this.setUsernamesList(accountModule.user.email)
          }

          console.log(accountModule.user.email.toLowerCase())

          const savedFilterValue: any = localStorage.getItem('tasqFilterValue');
          if (savedFilterValue) {
            this.setSubstringFilter(savedFilterValue);
          }

          const savedActiveOptions = localStorage.getItem('tasqActiveOptions');
          if (savedActiveOptions && JSON.parse(savedActiveOptions)) {
            this.setPredictionTypeFilter(JSON.parse(savedActiveOptions));
          }
        }

        let level = this.tasqListLevel;
        if (this.activePage === 'Kanban') {
          level = 'Well';
        }

        const SubstringFilter = this.activeSubstringFilter;
        let variables = {
          Timezone: 'America/Denver',
          Operator: getConfigEnv('OPERATOR_LOWERCASED'),
		    Level: level,
          // @ts-ignore
          To: this.tasqEndLimit,
          // @ts-ignore
          From: this.tasqStartLimit,
          // @ts-ignore
          skipTotalTasqs: true,
          skipTodaysTasqs: true,
          ...(this.usernamesList.length && { Usernames: this.usernamesList }),
          ...((this.activeFilterTeam.length) && {Teams : this.activeFilterTeam} ),
          ...((this.activeFilterRoute.length) && {Routes: this.activeFilterRoute} ),
          ...((!isNaN(Number(this.cycleTimeGreaterThanFilter)) && Number(this.cycleTimeGreaterThanFilter)) && {CycleTimeGreaterThanFilter: Number(this.cycleTimeGreaterThanFilter)}),
          ...((!isNaN(Number(this.cycleTimeLessThanFilter)) && Number(this.cycleTimeLessThanFilter) !== 0) && {CycleTimeLessThanFilter: Number(this.cycleTimeLessThanFilter)}),
          ...((!isNaN(Number(this.unitsGreaterThanFilter)) && Number(this.unitsGreaterThanFilter) !== 0) && {UnitsGreaterThanFilter: Number(this.unitsGreaterThanFilter)}),
          ...((!isNaN(Number(this.unitsLessThanFilter)) && Number(this.unitsLessThanFilter) !== 0) && {UnitsLessThanFilter: Number(this.unitsLessThanFilter)})
        };


        if ( this.activePage === 'Tasq' && this.usernamesList.length) {
          variables.Usernames = this.usernamesList;
        }

        if (this.activePredictionTypeFilter.length !== this.allPredictionTypes.length && this.activePredictionTypeFilter.length > 0) {
          // @ts-ignore
          variables.FilterByPredictionType = this.activePredictionTypeFilter;
        }

        this.setActiveFilterList(queryPrams);

        const todaysTasqResults: any[] = [];
        const completedTasqResults: any[] = [];

        if (this.currentTasqListPageNo === 1 || this.tasqStartLimit === 0 || !SubstringFilter) {
          if (!SubstringFilter && level === 'Well') {
            variables.skipTodaysTasqs = false;
          }

          if (this.currentTasqListPageNo === 1 || this.tasqStartLimit === 0) {
            this.setIsLoadingTasqs(true);
          }
         

          let tasqsLists : any;

          const offlineTasqData = await localForage.getItem('saveOfflineMyTasqList');
  
          if (await isOnline()) {
            const {
              data: {
                get_tasq_list: newTasqsLists,
              },
            }: GetTasqsListsResponse = await predictionsApolloClient.query({
              query: getTasqsLists,
              variables,

            });
            // @ts-ignore
            tasqsLists = newTasqsLists;
          } else if (offlineTasqData) {
            tasqsLists = offlineTasqData;
          }
		  

          if (tasqsLists.PredictionTypes) {
            this.setAllPredictionTypes(tasqsLists.PredictionTypes);
          }

          if (!SubstringFilter && level === 'Well') {
            for (var x = 0; x < tasqsLists.TodaysTasqResults.length; x++) {
              todaysTasqResults.push(JSON.parse(tasqsLists.TodaysTasqResults[x]));
            }

            const tasqsList = jsonParse(JSON.stringify(todaysTasqResults)).map(
              (t: TasqJobResponse) => mapDefaultTasq(t),
            );

            const todaysTasqLength: any = tasqsLists.TodaysTasqLength;
            this.setTotalTasqPagesLength(todaysTasqLength);
            this.setTasqListLevel(level);

            this.updateTasqList({
              data: tasqsList,
              listType: 'tasqList',
            });
          }

          for (var x = 0; x < tasqsLists.CompletedTasqs.length; x++) {
            completedTasqResults.push(JSON.parse(tasqsLists.CompletedTasqs[x]));
          }

          const completed = jsonParse(JSON.stringify(completedTasqResults)).map(
            (t: TasqJobResponse) => mapDefaultTasq(t),
          );

          this.setTasqList({
            data: completed,
            listType: 'completedTasqList',
          });
        }

        if ((SubstringFilter || level === 'Pad')) {
        // @ts-ignore
          delete variables.skipTotalTasqs;
          // @ts-ignore
          delete variables.skipTodaysTasqs;

		// @ts-ignore
		delete variables.Usernames;

          if(variables.Routes){
            variables.FilterByRoutes = variables.Routes
            delete variables.Routes;
          }
	      // @ts-ignore
          //   delete variables.Level;
          if (SubstringFilter) {
            // @ts-ignore
            variables.SubstringFilter = SubstringFilter;
          }
          if (this.activePredictionTypeFilter.length !== this.allPredictionTypes.length && this.activePredictionTypeFilter.length > 0) {
            // @ts-ignore
            variables.FilterByPredictionType = this.activePredictionTypeFilter;
          }

	  let skipTotalTasqs = true;
	  let skipTotalProducing = true;
	  if (this.padNestedTasqs.length == 0) {
            skipTotalTasqs = false;
            skipTotalProducing = false;
	  }

	  const updated_variables = {
            skipTotalTasqs,
            skipTotalProducing,
            input: {
		  ...variables,
      ...(this.usernamesList.length && { Usernames: this.usernamesList }),
            },
	  };
          let searchLists: any;
          const offlinePadData = await localForage.getItem('saveOfflineMyPadList');
          if(await isOnline()) {
            const {
              data: {
                search_tasqs: newSearchLists,
              },
            }: SearchTasqsResponse = await predictionsApolloClient.query({
              query: searchTasqs,
              variables: updated_variables,
            });
            searchLists = newSearchLists
          } else {
              console.log(offlinePadData)
              searchLists = offlinePadData;

          }

          this.setTasqListLevel(level);
          if (this.activeSubstringFilter) {
            for (var x = 0; x < JSON.parse(searchLists.Results).length; x++) {
              todaysTasqResults.push(JSON.parse(searchLists.Results)[x]);
            }
          } else {
            for (var x = 0; x < JSON.parse(searchLists.Results).length; x++) {
              todaysTasqResults.push(JSON.parse(searchLists.Results)[x]);
            }
          }
          const todaysTasqLength: any = searchLists.ResultTotal;
          this.setTotalTasqPagesLength(todaysTasqLength);
          const tasqsList = jsonParse(JSON.stringify(todaysTasqResults)).map(
            (t: TasqJobResponse) => mapDefaultTasq(t),
          );
		  

	  const additionalPadNestedTasqs: any[] = [];
	  for (var t = 0; t < tasqsList.length; t++) {
            if (tasqsList[t].wells.length == 0) {
              continue;
            }
            for (var r = 0; r < tasqsList[t].wells.length; r++) {
				tasqsList[t].wells[r] = mapDefaultTasq(tasqsList[t].wells[r]);
				additionalPadNestedTasqs.push(tasqsList[t].wells[r]);
            }
        }


	  this.setAdditionalPadNestedTasqs(additionalPadNestedTasqs);
	  if (this.padNestedTasqs.length == 0) {
            const padNestedTasqs: any[] = [];
            const nodeIDsAdded: string[] = [];
            const total_tasqs = JSON.parse(searchLists.TotalTasqs);
            for (var t = 0; t < total_tasqs.length; t++) {
              var mapped_tasq = mapDefaultTasq(total_tasqs[t]);
				padNestedTasqs.push(mapped_tasq);
				nodeIDsAdded.push(mapped_tasq.wellName);
            }
            const producing_tasqs = JSON.parse(searchLists.TotalProducing);
            for (var r = 0; r < producing_tasqs.length; r++) {
		  var mapped_tasq = mapDefaultTasq(producing_tasqs[r]);

		  if (!nodeIDsAdded.includes(mapped_tasq.wellName)) {
			  padNestedTasqs.push(mapped_tasq);
		  }
            }

            this.setPadNestedTasqs(padNestedTasqs);
	  }

          this.updateTasqList({
            data: tasqsList,
          });
        }
        this.setActiveList(list.val);
        this.updatePaginationDetails();
        this.setIsLoadingTasqs(false);
        
      }
      this.setPaginationLoading(true);
    }
  }

  @Action
  async getTasqsForOfflineUse(list: {
    text?: string,
    val: string,
  }): Promise<void> {
    const queryPrams = list;
    console.log(accountModule.user)

    this.setActiveFilterList(queryPrams);
    const variables = {
      Timezone: 'America/Denver',
      Operator: getConfigEnv('OPERATOR_LOWERCASED'),
	    Level: 'Well',
      // @ts-ignore
      skipTotalTasqs: true,
      skipTodaysTasqs: false,
      To: 25,
      // @ts-ignore
      From: 0,
      Username: accountModule.user.email.toLowerCase(),
	  };
    const {
      data: {
        get_tasq_list: tasqsLists,
      },
    }: GetTasqsListsResponse = await predictionsApolloClient.query({
      query: getTasqsLists,
      variables,
    });


    const {
      data: {
        search_tasqs: searchLists,
      },
    }: SearchTasqsResponse = await predictionsApolloClient.query({
      query: searchTasqs,
      variables: {
        skipTotalProducing: false,
        skipTotalTasqs: false,
        input: {
          From: 0,
          Level: 'Pad',
          Timezone: 'America/Denver',
          Operator: getConfigEnv('OPERATOR_LOWERCASED'),
          To: 25,
          Username: accountModule.user.email.toLowerCase(),
        }
      }
    });


    await localForage.setItem('saveOfflineMyPadList', searchLists);
    await localForage.setItem('saveOfflineMyTasqList', tasqsLists);
  
    let todaysTasqs : any = [];
    for (var x = 0; x < tasqsLists.TodaysTasqResults.length; x++) {
      todaysTasqs.push(JSON.parse(tasqsLists.TodaysTasqResults[x]));
      
    }
    const tasqsListUpdated = jsonParse(JSON.stringify(todaysTasqs)).map(
      (t: TasqJobResponse) => mapDefaultTasq(t),
    );
    await localForage.setItem('saveOfflineTodayTasqList', tasqsListUpdated);
    // for (var x = 0; x < tasqsLists.CompletedTasqs.length; x++) {
    //   console.log(JSON.parse(tasqsLists.CompletedTasqs[x]));
    // }
  }

  @Action
  async getTasq({
	  PredictionID,
  }) {
    const {
      data: {
        get_tasq: {
          prediction: Prediction,
        },
      },
    } = await workflowApolloClient.query({
      query: getTasq,
      variables: {
        operator: getConfigEnv('OPERATOR_LOWERCASED'),
        prediction_id: PredictionID,
      },
      fetchPolicy: 'network-only',
    });
    const tasq = mapDefaultTasq(JSON.parse(Prediction));
    this.setActiveTasqExplicit(tasq);

    // this.setWaitingTasqs(waitingTasqs);
  }

  @Action
  async getWaitList() {
    if (await isOnline()) {
      const {
        data: {
          get_my_waitlist: {
            results: waitListResults,
          },
        },
      } = await workflowApolloClient.query({
        query: getMyWaitList,
        variables: {
          input: {
		  Operator: getConfigEnv('OPERATOR_LOWERCASED'),
          },
        },
        fetchPolicy: 'network-only',
      });
      const waitingTasqs = waitListResults.map(
        (r) => mapDefaultTasq(jsonParse(r)),
      );

      this.setWaitingTasqs(waitingTasqs);
      await localForage.setItem('getWaitList', waitingTasqs);
    } else {
      const result = await localForage.getItem('getWaitList');
      if (result) {
        this.setWaitingTasqs(result);
      }
    }
  }

  @Action
  async getTasqEventHistoryList(worklflowTaskID) {
	this.setTasqEventHistoryList([]);
    if (await isOnline()) {
      const {
        data: {
          get_tasq_event_history: {
            Results: tasqEventResults,
          },
        },
      } = await workflowApolloClient.query({
        query: getTasqEventHistory,
        variables: {
          input: {
            WorkflowTaskID: worklflowTaskID,
          },
        },
        fetchPolicy: 'network-only',
      });
      this.setTasqEventHistoryList(tasqEventResults);
      await localForage.setItem(`getTasqEventHistoryList;${worklflowTaskID}`, tasqEventResults);
    } else {
      const result = await localForage.getItem(`getTasqEventHistoryList;${worklflowTaskID}`);
      if (result) {
        this.setTasqEventHistoryList(result);
      }
    }
  }




  @Action
  async getTasqListResponses() {
    if(await isOnline()) {
      this.setIsLoadingTasqResponses(true);
      const {
        data: {
          get_all_tasq_responses: {
            Results: tasqResponseResults,
          },
        },
      } = await workflowApolloClient.query({
        query: getAllTasqResponses,
        variables: {
          input: {
            Operator: getConfigEnv('OPERATOR_LOWERCASED'),
          },
        },
        fetchPolicy: 'network-only',
      });

      var parsed_responses = JSON.parse(tasqResponseResults);
	
      this.setTasqResponseResults(parsed_responses);
      this.setIsLoadingTasqResponses(false);
      await localForage.setItem('getTasqListResponses', parsed_responses);
    } else {
      const result = await localForage.getItem('getTasqListResponses');
      if (result) {
        this.setTasqResponseResults(result);
      }
    }
	
  }







  get notCompletedTasqs() {
    return this.tasqList;
  }

  get tasqById() {
    return (id: string): TasqJob|undefined => [...this.tasqList, ...this.completedTasqList, ...this.waitingTasqList, ...this.padNestedTasqs, ...this.additionalPadNestedTasqs].find((t) => t.id.toLowerCase() === id.toLowerCase());
  }

  get activeTasqsList() {
    return this.activeListType;
  }
}

export default getModule(TasqsListModule);
